.product-details-latest {
  // .new-arrival-badge {
  //   font: normal normal medium 15px/18px "Afacad Flux", serif;
  //   letter-spacing: 3.6px;
  //   color: #999999;
  //   text-transform: uppercase;
  // }

  // .product-title {
  //   font: normal normal 600 32px/43px "Vidaloka", serif;
  //   letter-spacing: 0.48px;
  //   color: #333333;
  // }

  // .metal-description {
  //   font: normal normal medium 16px/19px "Afacad Flux", serif;
  //   letter-spacing: 1.6px;
  //   color: #672a2f;
  // }

  // .product-price {
  //   font: normal normal 900 26px/32px "Afacad Flux", serif;
  //   letter-spacing: 0px;
  //   color: #333333;
  //   text-transform: uppercase;
  // }

  // .product-description {
  //   font: normal normal normal 16px/22px "Afacad Flux", serif;
  //   letter-spacing: 0px;
  //   color: #666666;
  // }

  .product-details {
    .product-details-title-link {
      text-decoration: underline;
      font: normal normal medium 24px/28px "Afacad Flux", serif;
      font-size: 25px;
      letter-spacing: 1.8px;
      color: #333333;
      text-transform: uppercase;
    }

    div {
      padding-top: 0.2rem;
      font: normal normal normal 16px/30px "Afacad Flux", serif;
      color: #666666;
      font-size: 18px;
    }
  }

  .product-information-table {
    width: 100%;

    table,
    tr,
    td {
      border: 1px solid;
      padding: 15px;
    }
  }
}
.button-enquire{
font-family: "Vidaloka", serif;

}

@media screen and (max-width: 750px) {
  .grid-container {
    padding: 0 !important;
  }

  .product-details-wrapper {
    padding: 0 !important;
    padding: 1rem !important;
  }
}

.image-gallery-bullets .image-gallery-bullet {
  width: 10px !important;
  height: 10px !important;
}

.image-gallery-thumbnail {
  height: 100%;
  // width: 100%;
}

.slick-dots{
  bottom: -14px;
}

/* Change arrow colors */
.slick-prev:before,
.slick-next:before {
  color: gray !important;  /* Change arrow color */
  font-size: 24px !important; /* Adjust size if needed */
  opacity: 1 !important;
  // background-color: white !important;
}
.slick-prev:hover:before,
.slick-next:hover:before {
  color: #672A2F !important;
  background: #fff; /* Keep color the same */
}

.slick-next{
  z-index: 1;
 right: 32px;
 width: 0px !important;         
 height: 0px !important;
}
.slick-prev{
  z-index: 1;
 left: 3px;
 width: 0px !important;         /* Adjust width */
 height: 0px !important;  
}

.slick-dots li {
  height: 45px;
  width: 60px;
  margin: 0 5px;

}
.slick-dots.slick-thumb li.slick-active a img {
  border: 2px solid gray

}
.slick-dots.slick-thumb {
  bottom: -10px; /* Adjust spacing */
}

// .slick-list {
//   overflow: visible ;
// }

// .slick-list {
//   clip-path: inset(0 0 0 0);
//   overflow: hidden !important;
// }












